.chart-container {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 30px;
      padding: 20px;
    }
    
    .chart-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      padding:10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
    
    .chart-item h3 {
      margin-bottom: 10px;
    }
    